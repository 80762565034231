@use 'base-styles/overrides/whitelabel' as wlo;
@use 'base-styles/abstracts/typography-presets/whitelabel-styles' as
  typography-preset;
@use 'base-styles/abstracts/variables/all' as v;

@mixin edf() {
  @include v.defaults();

  --primary-800: #c82c00;
  --primary-700: #e03100;
  --primary-600: #a02300;
  --primary-500: #ffb210;
  --primary-400: #ffd375;
  --primary-300: #ffe3a8;

  --secondary-800: #000036;
  --secondary-700: #10367a;
  --secondary-600: #1057c8;
  --secondary-500: #1089ff;
  --secondary-400: #97bfe3;
  --secondary-300: #e5eff8;

  --success-800: #005500;
  --success-700: #008a00;
  --success-600: #4f9e30;
  --success-500: #88d910;
  --success-400: #a2f02e;
  --success-300: #c0e410;

  --warning-800: #a87200;
  --warning-700: #db9500;
  --warning-600: #ffb210;
  --warning-500: #ffc242;
  --warning-400: #ffd375;
  --warning-300: #ffe3a8;

  --error-800: #901328;
  --error-700: #be1935;
  --error-600: #c82c00;
  --error-500: #e9536d;
  --error-400: #ef8193;
  --error-300: #f4aeba;

  --tone-900: #141414;
  --tone-800: #2d2d2d;
  --tone-700: #474747;
  --tone-600: #666666;
  --tone-500: #ababab;
  --tone-400: #d3d3d3;
  --tone-300: #f1f1f1;
  --tone-200: #f1f1f1;
  --tone-100: #ffffff;

  @include wlo.rule-overrides();
  @include wlo.set-vars();
  @include typography-preset.set-vars();

  //todo: these should be deprecated but setting here to default to colors from above palette at least
  --accent: var(--primary-700);
  --warn: var(--warning-600);
  --accept: var(--success-600);
  --primary-contrast: var(--tone-100);

  --error: var(--error-600);
  --warning: var(--warning-600);
  --success: var(--success-600);

  //main copy, field values
  --text-color: var(--tone-800);
  //field labels, hints
  --text-color--supporting: var(--tone-800);
  --text-color--unimportant: var(--tone-800);
  //placeholder, unimportant text
  --text-color--placeholder: var(--tone-600);

  //page headings, important card headings
  --heading--highlight-color: var(--secondary-700);

  --primary-interactive-color: var(--primary-700);
  --primary-interactive-color--hover: var(--primary-800);
  --primary-interactive-color--active: var(--primary-800);
  --primary-interactive-contrast-color: var(--tone-100);
  --primary-interactive-contrast-color--hover: var(--tone-100);
  --primary-interactive-contrast-color--active: var(--tone-100);

  // Use for:
  // tertiary & toggle buttons
  // card highlight (when triggered by secondary element)
  --secondary-interactive-color: var(--secondary-700);
  --secondary-interactive-color--hover: var(--secondary-800);
  --secondary-interactive-color--active: var(--secondary-800);
  --secondary-interactive-contrast-color: var(--tone-100);
  --secondary-interactive-contrast-color--hover: var(--tone-100);
  --secondary-interactive-contrast-color--active: var(--tone-100);

  //typeahead drop down selected item bg
  --typeahead-bg-color: var(--tone-400);
  --typog-sm-body-regular-size: 16px;
  --typog-sm-body-bold-size: 16px;
  --typog-sm-caption-link-size: 14px;
  --typog-sm-caption-regular-size: 14px;
  --typog-caption-regular-line-height: 18px;

  //footer bg
  --footer: var(--primary-700);
  //footer text
  --footer--contrast: var(--tone-100);
  //footer heading
  --footer-contrast-loud: var(--tone-100);

  --separator: var(--tone-400);
  --panel-bg--highlight: var(--tone-300);

  --border-radius-sm: 0;
  --border-radius-md: 0;
  --border-radius-lg: 4px;

  //information banners and cards
  --info-card-bg: #f0f0f0;

  // todo: these button styles align with the sales current journey. They are currently defined within the dg-new.scss file. However, assuming that the sales journey is the current whitelable, they should be set as defaults within _button.scss and then overridden within themes style files that differ from the detault.
  // Buttons
  --btn-height: auto;
  --btn-width: auto;
  --btn-min-width: 254px;
  --btn-max-width: 100%;
  --btn-min-width-sm: 100%;
  --btn-max-width-sm: 100%;

  // Form
  --typog-lg-body-large-bold-size: 16px;
  --input-padding: 12px;
}
