/* You can add global styles to this file, and also import other style files */
@use 'base-styles/base-styles';
@use 'theme' as theme;

.edf {
  @include theme.edf();
}

.grecaptcha-badge {
  visibility: hidden;
}
